import { HStack, Stack } from "@chakra-ui/react";
import { endOfDay } from "date-fns";
import moment from "moment";
import { Moment } from "moment";
import { useState } from "react";
import {
  defaultTimeButtons,
  TimeButtonOptions,
  TimeRangeButtons,
} from "../../components/button/TimeRangeButtons";
import { DateRangePicker } from "../../components/datepicker/DateRangePicker";
import { ReadingResolution } from "../../lib/api/readings/hooks";

export interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

export interface MomentDateRange {
  startDate: Moment;
  endDate: Moment;
}

export interface MomentDateRangeOptional {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface DateRangeControlsProps {
  setNewDateRange: (dateRange: MomentDateRange) => void;
  selectedDateRange: MomentDateRange;
  buttonOptions?: TimeButtonOptions[];
  maxSelectedDays?: number;
  //   setDateRange: Dispatch<SetStateAction<DateRange>>;
  //   setTimeRangeDays: (days: number) => void;
}

export interface DateRangeResolution {
  powerResolution: ReadingResolution;
  environmentalResolution: ReadingResolution;
}

export const getResolutionDaysForRange = (dateRange: MomentDateRangeOptional): DateRangeResolution => {
  if (!dateRange.endDate || !dateRange.startDate) {
    return {
      powerResolution: "HOURLY",
      environmentalResolution: "HOURLY",
    };
  }

  const fromDiff = dateRange.endDate;
  const toDiff = dateRange.startDate;
  const days = fromDiff.diff(toDiff, "days");

  let powerResolution: ReadingResolution = "HOURLY";

  // power

  if (days > 25) {
    powerResolution = "DAILY";
  } else if (days >= 2) {
    powerResolution = "HOURLY";
  } else {
    powerResolution = "FIFTEENMINUTE";
  }

  // environmental
  const environmentalResolution: ReadingResolution = days > 4 ? "HOURLY" : "FIFTEENMINUTE";
  return {
    environmentalResolution,
    powerResolution,
  };
};

const getSelectedButtonFromDateRange = (
  selectedDateRange: MomentDateRange,
  buttonOptions: TimeButtonOptions[]
) => {
  // end date is start date check if is one of the known ranges
  const match = buttonOptions.find((button) => {
    // Check end dates match
    if (moment(button.endDate).format("YYYY-MM-DD") !== selectedDateRange.endDate.format("YYYY-MM-DD")) {
      return false;
    }

    // if we are checking within a day, check the hours match also
    const dateFormat = button.hours > 24 ? "YYYY-MM-DD" : "YYYY-MM-DD HH";

    // Check start dates match
    const startDate = moment(button.endDate).subtract(button.hours, "hours");
    if (startDate.format(dateFormat) !== selectedDateRange.startDate.format(dateFormat)) {
      return false;
    }

    return true;
  });

  return match || null;
};

export const maxReadingDays = 366;

export const DateRangeControls = ({
  setNewDateRange,
  selectedDateRange,
  buttonOptions = defaultTimeButtons,
  maxSelectedDays: maxSelectedDaysProp = maxReadingDays,
}: DateRangeControlsProps) => {
  const maxSelectedDays = maxSelectedDaysProp === -1 ? undefined : maxSelectedDaysProp;

  const [customDateRange, setCustomDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  const setNewTimeRangeFromButton = (button: TimeButtonOptions) => {
    // const resolution: ReadingResolution = days >= 2 ? "HOURLY" : "FIFTEENMINUTE";
    const endDate = button.hours < 25 ? moment(button.endDate) : moment(button.endDate).endOf("day");
    const startDate = moment(endDate).subtract(button.hours, "hours");

    return setNewDateRange({
      startDate,
      endDate,
    });
  };

  const selectedButton = getSelectedButtonFromDateRange(selectedDateRange, buttonOptions);
  return (
    <HStack justifyContent={"flex-end"}>
      <HStack>
        <Stack bg={"white"} p={1} border="1px" borderColor="gray.200" borderRadius="md">
          <TimeRangeButtons
            onSelect={setNewTimeRangeFromButton}
            overridden={selectedButton === null}
            selectedValue={selectedButton}
            buttons={buttonOptions}
          />
        </Stack>
        <DateRangePicker
          startDate={customDateRange.startDate ? customDateRange.startDate : null}
          endDate={customDateRange.endDate ? customDateRange.endDate : null}
          onDatesChange={(data) => {
            setCustomDateRange({
              startDate: data.startDate,
              endDate: data.endDate && endOfDay(data.endDate),
            });
            if (data.endDate) {
              setNewDateRange({
                startDate: moment(data.startDate).startOf("day"),
                endDate: moment(data.endDate).endOf("day"),
              });
            }
          }}
          bg={selectedButton === null ? "black" : "white"}
          color={selectedButton === null ? "white" : undefined}
          borderColor="gray.100"
          borderRadius={6}
          maxBookingDate={new Date()}
          horizontalOffset={-460}
          maxSelectedDays={maxSelectedDays}
          showDateRangeOnButton={selectedButton === null}
          onClick={() => {
            // If the custom date button is clicked and a range has already been specified there, select it
            if (customDateRange.startDate && customDateRange.endDate) {
              setNewDateRange({
                startDate: moment(customDateRange.startDate).startOf("day"),
                endDate: moment(customDateRange.endDate).endOf("day"),
              });
            }
          }}
        />
      </HStack>
    </HStack>
  );
};

export default DateRangeControls;
