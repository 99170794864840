import { Box, BoxProps, HStack, Text, theme, Tooltip } from "@chakra-ui/react";
import {
  ComfortScore,
  ComfortScoreEmojis,
  getComforScoreBGColor,
  getComfortRangeDisplayText,
  getComfortScoreColor,
  getComfortScoreDisplayText,
} from "../../lib/api/types/Room";
import { lighten } from "@chakra-ui/theme-tools";

interface ComfortScoreTagProps extends BoxProps {
  comfortScore?: ComfortScore;
  lightenColor?: number;
  lightenBackgroundColor?: number;
}
export const ComfortScoreTag = ({
  comfortScore,
  lightenColor,
  lightenBackgroundColor,
  ...others
}: ComfortScoreTagProps) => {
  if (!comfortScore) {
    return null;
  }

  return (
    <Tooltip label={getComfortRangeDisplayText(comfortScore)}>
      <Box
        display="flex"
        alignItems="center"
        height="24px"
        m="4"
        borderRadius="200px"
        bg={
          lightenBackgroundColor
            ? lighten(getComforScoreBGColor(comfortScore), lightenBackgroundColor / 2)(theme)
            : getComforScoreBGColor(comfortScore)
        }
        flexShrink={0}
        {...others}
      >
        <Text
          fontWeight="600"
          fontSize="12px"
          px="2"
          color={
            lightenColor
              ? lighten(getComfortScoreColor(comfortScore), lightenColor)(theme)
              : getComfortScoreColor(comfortScore)
          }
        >
          {getComfortScoreDisplayText(comfortScore) + " " + ComfortScoreEmojis[comfortScore]}
        </Text>
      </Box>
    </Tooltip>
  );
};
