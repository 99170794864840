import { QueryOptions, useQuery } from "react-query";
import { useAuth } from "../../auth/useAuth";
import { useAggregatedQueries } from "../../hooks/useAggregatedQueries";
import { buildQueryParam } from "../../utils/buildQueryParams";
import { api } from "../api";
import {
  OrganisationInsights,
  PropertyInsights,
  PropertyOccupancyInsights,
  RoomInsights,
} from "../types/Insights";

interface InsightsParams {
  startDate: string;
  endDate: string;
}
interface OccupancyInsightParams extends InsightsParams {
  viewType: "live" | "trending";
}

export enum QueryKeys {
  PROPERTY_INSIGHTS = "property-insights",
  PROPERTY_OCCUPANCY_INSIGHTS = "property-occupancy-insights",
  ROOM_INSIGHTS = "room-insights",
  ORGANISATION_INSIGHTS = "organisation-insights",
}

export const useGetPropertyInsights = (propertyId: string, queryParams: InsightsParams) => {
  const queryString = buildQueryParam(queryParams);

  const { data: propertyInsights, ...rest } = useQuery<PropertyInsights, Error>(
    [QueryKeys.PROPERTY_INSIGHTS, propertyId, queryString],
    () => api.get(`/insights/v1/property/${propertyId}?${queryString}`),
    {
      enabled: Boolean(propertyId),
      staleTime: Infinity,
    }
  );
  return { propertyInsights, ...rest };
};

export const useGetPropertyOccupancyInsights = (propertyId: string, queryParams: OccupancyInsightParams) => {
  const queryString = buildQueryParam(queryParams);

  const { data: propertyOccupancyInsights, ...rest } = useQuery<PropertyOccupancyInsights, Error>(
    [QueryKeys.PROPERTY_OCCUPANCY_INSIGHTS, propertyId, queryString],
    () => api.get(`/insights/v1/property/${propertyId}/occupancy?${queryString}`),
    {
      enabled: Boolean(propertyId),
      staleTime: Infinity,
    }
  );
  return { propertyOccupancyInsights, ...rest };
};

export const useGetRoomInsights = (roomId: string, queryParams: InsightsParams) => {
  const queryString = buildQueryParam(queryParams);

  const { data: roomInsights, ...rest } = useQuery<RoomInsights, Error>(
    [QueryKeys.ROOM_INSIGHTS, roomId, queryString],
    () => api.get(`/insights/v1/room/${roomId}?${queryString}`),
    {
      enabled: Boolean(roomId),
      staleTime: Infinity,
    }
  );
  return { roomInsights, ...rest };
};

export const useGetInsightsForMultipleRooms = (
  roomIds: string[],
  queryParams: InsightsParams,
  options?: QueryOptions
) => {
  const queryString = buildQueryParam(queryParams);

  const getInsights = (roomId: string) =>
    api.get(`/insights/v1/room/${roomId}?${queryString}`) as Promise<RoomInsights>;
  const { data: roomInsightsArray, ...rest } = useAggregatedQueries(
    (roomIds || []).map((roomId) => ({
      queryKey: [QueryKeys.ROOM_INSIGHTS, roomId, queryString],
      queryFn: () => getInsights(roomId),
      staleTime: Infinity,
      ...options,
      enabled: Boolean(roomId),
    }))
  );

  return { roomInsightsArray: roomInsightsArray as RoomInsights[], ...rest };
};

export const useGetOrganisationInsights = (queryParams: InsightsParams) => {
  const { user } = useAuth();
  const queryString = buildQueryParam(queryParams);
  const organisationId = user?.currentOrganisationId;

  const { data: organisationInsights, ...rest } = useQuery<OrganisationInsights, Error>(
    [QueryKeys.ORGANISATION_INSIGHTS, organisationId, queryString],
    () => api.get(`/insights/v1/organisation/${organisationId}?${queryString}`),
    {
      enabled: Boolean(organisationId),
      staleTime: Infinity,
    }
  );
  return { organisationInsights, ...rest };
};
