import { ComfortScore } from "./Room";

export enum ComfortIndexCategory {
  cold = "cold",
  muggyAndCold = "muggyAndCold",
  dryAndCold = "dryAndCold",
  muggy = "muggy",
  dry = "dry",
  dryAndHot = "dryAndHot",
  hot = "hot",
  muggyAndHot = "muggyAndHot",
  perfectComfort = "perfectComfort",
}

export const ComfortIndexCategoryMap: Record<ComfortIndexCategory, ComfortScore> = {
  [ComfortIndexCategory.dryAndHot]: ComfortScore.DryAndHot,
  [ComfortIndexCategory.hot]: ComfortScore.Hot,
  [ComfortIndexCategory.muggyAndHot]: ComfortScore.MuggyAndHot,
  [ComfortIndexCategory.dry]: ComfortScore.Dry,
  [ComfortIndexCategory.muggy]: ComfortScore.Muggy,
  [ComfortIndexCategory.dryAndCold]: ComfortScore.DryAndCold,
  [ComfortIndexCategory.cold]: ComfortScore.Cold,
  [ComfortIndexCategory.muggyAndCold]: ComfortScore.MuggyAndCold,
  [ComfortIndexCategory.perfectComfort]: ComfortScore.PerfectComfort,
};

export type ComfortIndexPercentages = Record<ComfortIndexCategory, number>;

export type AirborneIndexPercentages = {
  mid: number;
  high: number;
};

export enum MouldRiskCategory {
  VeryLow = "veryLow",
  Low = "low",
  Mid = "mid",
  High = "high",
  VeryHigh = "veryHigh",
}

export type MouldRiskPercentages = Record<MouldRiskCategory, number>;

export interface PeriodInsights {
  healthScore: number;
  productivityPercentage?: number;
  comfortIndexPercentages: ComfortIndexPercentages;
  airborneIndexPercentages: AirborneIndexPercentages;
  mouldRiskPercentages: MouldRiskPercentages;
}

export interface RoomOccupancyInsights {
  roomId: string;
  capacityPercentage: number | null;
  occupancy: number | null;
  usagePercentage: number;
}

export interface OccupancyInsights {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  property: any;
  rooms: RoomOccupancyInsights[];
}

export interface PropertyOccupancyInsights extends Omit<PropertyInsights, "insights"> {
  insights: OccupancyInsights;
}

export interface PropertyInsights {
  propertyId: string;
  startDate: string;
  endDate: string;
  insights: PeriodInsights;
}

export interface RoomInsights {
  roomId: string;
  startDate: string;
  endDate: string;
  insights: PeriodInsights;
}

export interface OrganisationInsights {
  organisation: string;
  startDate: string;
  endDate: string;
  insights: PeriodInsights;
  properties: PropertyInsights[];
}

export interface Insights {
  healthScore?: number;
  /** Percentages of time (0-1) that environment was at each comfort level */
  comfortIndexPercentages?: {
    [key in ComfortIndexCategory]: number;
  };
  /** Percentage of time (0-1) that environment was productive */
  productivityPercentage?: number;
  /** Percentages of time (0-1) that environment was at airborne index levels */
  airborneIndexPercentages?: {
    high: number;
    mid: number;
  };
  /** Cumulative mould risk over the day */
  mouldRisk?: [number, number][];
}

export type ReadingsOf<T> = {
  [key in keyof T]: T[key] extends number | [number, number][] | undefined
    ? [number, number][]
    : ReadingsOf<T[key]>;
};
export type InsightsReadings = ReadingsOf<Insights>;

export interface RoomLocationInsightsReadings {
  id: string;
  readings: InsightsReadings;
}
